<template>
  <div>
    <b-card no-body class="mb-0">
      <!-- Projeler-->

      <b-card-body>
        <v-select
          class="mb-1"
          :placeholder="lang('t_project')"
          v-model="selected_project"
          label="display_name"
          :reduce="(val) => val.internal_name"
          :options="projects"
        >
        </v-select>
        <v-select
          class="mb-1"
          :placeholder="lang('t_queue')"
          v-model="selected_queue"
          label="display_name"
          :reduce="(val) => val.internal_name"
          :options="queues.filter((e) => e.project == selected_project)"
        >
        </v-select>

        <!-- Dosya Yükleme-->
        <!-- <div class="d-flex align-items-center"> -->
        <b-input-group>
          <b-input-group-prepend>
            <b-dropdown
              variant="outline-secondary"
              :text="selected_type.display_name"
            >
              <b-dropdown-item
                @click="selected_type = item"
                v-for="item in types"
                :key="item.internal_name"
                >{{ item.display_name }}</b-dropdown-item
              >
            </b-dropdown>
          </b-input-group-prepend>
          <b-form-file
            class="w-75 pl-50"
            @input="read_excel"
            v-model="files"
            :placeholder="lang('t_uploadFileOnClick')"
            :drop-placeholder="lang('t_uploadFile')"
            accept=".xlsx"
            :browse-text="lang('t_uploadFile')"
          />
        </b-input-group>
        <div class="mt-50">
          <b-link
            href="template_examples/voyce_data_pattern.xlsx"
            :exact="true"
            class="text-secondary mt-1"
            active-class="text-primary"
          >
            <!-- <i class="bi bi-download mr-1"></i> -->
            <span class="text-muted"> {{ lang("t_exampleFile") }}</span>
          </b-link>
        </div>

        <!-- <b-button
        class="mt-50 px-50 py-50"
        href="template_examples/voyce_data_pattern.xlsx"
        :exact="true"
        variant="flat-secondary"
      >
        {{ lang("t_exampleFile") }}
      </b-button> -->

        <!-- <v-select
          class="w-25 pr-50"
          :placeholder="lang('t_type')"
          v-model="selected_type"
          label="display_name"
          :reduce="(val) => val.internal_name"
          :options="types"
          :clearable="false"
        >
        </v-select> -->

        <!-- </div> -->

        <!-- CheckBox -->

        <b-form-checkbox class="mt-1" v-model="set_passive_unmatch_records">
          {{ lang("t_setUnmatchedRecordsToPassive") }}
        </b-form-checkbox>

        <b-form-checkbox v-model="update_match_records">
          {{ lang("t_setUnmatchedRecordsToUpdate") }}
        </b-form-checkbox>

        <b-form-checkbox v-model="set_passive">
          {{ lang("t_setAllPreviousInstitutionDataToPassive") }}
        </b-form-checkbox>

        <b-form-checkbox v-model="set_attempts">
          {{ lang("t_setAttemptsByPhone") }}
        </b-form-checkbox>
        <!-- <div class="demo-inline-spacing">
            <b-form-checkbox v-model="set_attempts">
              {{ lang("t_setAttemptsByPhone") }}
            </b-form-checkbox>
          </div> -->
      </b-card-body>

      <!-- Kaydet Butonu -->

      <b-card-footer footer-class="py-1 " class="mt-50 py-1">
        <div class="d-flex align-items-center">
          <b-button @click="closeModal" class="ml-auto" variant="secondary">{{
            lang("t_cancel")
          }}</b-button>
          <b-button
            class="ml-1"
            @click="
              selected_type.internal_name == 'advance'
                ? upload()
                : uploadBasic()
            "
            variant="primary"
            style="float: right"
          >
            <span v-if="!is_progress">{{ lang("t_upload") }}</span>
            <b-spinner small v-else />
          </b-button>
        </div>

        <!-- <b-button
          class="mr-1"
          href="template_examples/voyce_data_pattern.xlsx"
          :exact="true"
          variant="success"
          style="float: right"
        >
          {{ lang("t_exampleFile") }}
        </b-button> -->
      </b-card-footer>
    </b-card>

    <b-tabs v-if="false">
      <b-tab :title="lang('t_advanced')"> </b-tab>
      <b-tab :title="lang('t_basic')">
        <b-card>
          <!-- Projeler-->
          <v-select
            class="mb-1"
            :placeholder="lang('t_project')"
            v-model="selected_project"
            label="display_name"
            :reduce="(val) => val.internal_name"
            :options="projects"
          >
          </v-select>
          <v-select
            class="mb-1"
            :placeholder="lang('t_queue')"
            v-model="selected_queue"
            label="display_name"
            :reduce="(val) => val.internal_name"
            :options="queues.filter((e) => e.project == selected_project)"
          >
          </v-select>

          <!-- Dosya Yükleme-->

          <b-form-file
            @input="read_excel"
            v-model="files"
            :placeholder="lang('t_uploadFileOnClick')"
            :drop-placeholder="lang('t_uploadFile')"
            accept=".xlsx"
            :browse-text="lang('t_uploadFile')"
          />

          <!-- CheckBox -->
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="set_passive_unmatch_records">
              {{ lang("t_setUnmatchedRecordsToPassive") }}
            </b-form-checkbox>
          </div>

          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="update_match_records">
              {{ lang("t_setUnmatchedRecordsToUpdate") }}
            </b-form-checkbox>
          </div>

          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="set_passive">
              {{ lang("t_setAllPreviousInstitutionDataToPassive") }}
            </b-form-checkbox>
          </div>

          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="set_attempts">
              {{ lang("t_setAttemptsByPhone") }}
            </b-form-checkbox>
          </div>

          <!-- Kaydet Butonu -->

          <b-button @click="uploadBasic" variant="primary" style="float: right">
            <span v-if="!is_progress">{{ lang("t_upload") }}</span>
            <b-spinner v-else />
          </b-button>
          <b-button
            class="mr-1"
            href="template_examples/voyce_data_pattern.xlsx"
            :exact="true"
            variant="success"
            style="float: right"
          >
            {{ lang("t_exampleFile") }}
          </b-button>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BFormFile,
  BFormSelect,
  BFormCheckbox,
  BButton,
  BSpinner,
  BTab,
  BTabs,
  BCardFooter,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BCardBody
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import vSelect from "vue-select";

globalThis.xw = function xw(data, cb) {
  var worker = new Worker("./js/xlsx-worker.js");
  worker.onmessage = function (e) {
    switch (e.data.t) {
      case "ready":
        break;
      case "e":
        console.error(e.data.d);
        break;
      case "xlsx":
        cb(JSON.parse(e.data.d));
        break;
    }
  };
  worker.postMessage({ d: data, b: "binary" });
};

export default {
  components: {
    BCardBody,
    BCardFooter,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BCardCode,
    BFormFile,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BSpinner,
    BTabs,
    BTab,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem,
  },

  data: () => ({
    lang: _l(window.localStorage.getItem("language") || "tr"),
    filter_values: {
      selectedProject: [],
    },
    is_progress: false,
    files: {},
    queues: [],
    projects: [],
    selected_project: "",
    cols: {
      customers: [],
      products: [],
      phones: [],
    },
    data: {
      customers: [],
      products: [],
      phones: [],
    },
    selectedColumn: "",
    selected_cols: [],
    set_passive: false,
    set_attempts: false,
    set_passive_unmatch_records: false,
    update_match_records: false,
    selected_type: { internal_name: 'advance', display_name: globalThis._lang('t_advanced') },
    types: [
      { internal_name: 'advance', display_name: globalThis._lang('t_advanced') },
      { internal_name: 'basic', display_name: globalThis._lang('t_basic') },
    ]
  }),
  methods: {
    closeModal() {

      globalThis.crm.dynamic_modal = false;
    },
    onexport(tmp_data) {
      let _data = [];
      for (const row of tmp_data) {
        let obj = {};

        for (const column of Object.keys(row)) {
          // let key = _headers[column] == undefined ? column : _headers[column];
          obj[column] = row[column];
        }
        _data.push(obj);
      }

      var data = this.$xlsx.utils.json_to_sheet(_data);

      // A workbook is the name given to an Excel file
      var wb = this.$xlsx.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      this.$xlsx.utils.book_append_sheet(wb, data, `duplicate_data`); // sheetAName is name of Worksheet

      // export Excel file
      this.$xlsx.writeFile(wb, `duplicate_data_${new Date().getTime()}.xlsx`); // name of the file is 'book.xlsx'
    },

    get_projects: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/Project`)).data;
      this.projects = response;
    },
    get_queues: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/Queue`)).data;
      this.queues = response;

      //
    },
    read_xlsx_into_variables(file) {
      return new Promise((resolve, reject) => {
        this.is_progress = true;
        const reader = new FileReader();
        reader.onload = (e) => {
          const bstr = e.target.result;
          globalThis.xw(bstr, (jsondata) => {
            this.data.customers = jsondata.customers;
            this.cols.customers = jsondata.customers[0];

            // this.data.products = jsondata.products;
            // this.cols.products = jsondata.products[0];

            this.data.phones = jsondata.phones;
            this.cols.phones = jsondata.phones[0];

            this.is_progress = false;
            resolve();
          });
        };
        reader.readAsBinaryString(file);
      });
    },
    async read_excel() {
      await this.read_xlsx_into_variables(this.files);
      console.log("data", this.data);
      console.log("cols", this.cols);
    },
    async upload() {
      /* if (this.update_match_records && this.selected_cols.length == 0) {
        this.snackbarMessage = "Lütfen güncellenecek olan başlıkları seçiniz.";
        this.snackbarColor = 'warning';
        this.snackbar = true;
        return;
      } */
      this.is_progress = true;

      try {
        let formData = new FormData();
        formData.append("files", this.files, this.files.name);
        formData.append("selected_queue", this.selected_queue);
        formData.append("selectedColumn", this.selectedColumn);
        formData.append("selected_cols", this.selected_cols);
        formData.append("collection", `${this.selected_project}_customers`);
        formData.append("set_passive", this.set_passive);
        formData.append("set_attempts", this.set_passive);

        formData.append(
          "set_passive_unmatch_records",
          this.set_passive_unmatch_records
        );
        formData.append("update_match_records", this.update_match_records);

        let response = await this.$http_in.post(
          "crm/v1/UploadCRMCustomers",
          formData
        );

        // await this.SetCustomers();
        console.log("dataa", response);
        // if (response.data.data == undefined) {
        //   this.snackbarMessage = response.data;
        //   this.snackbarColor = 'warning';
        //   this.snackbar = true;
        //   this.is_progress = false;
        //   return;
        // }

        if (response.data.duplicate_records.length > 0) {
          // globalThis.window.location.href = `http://${globalThis.AppConfig.RESTAPI}/outputs/${response.data.url}.xlsx`;
          this.onexport(response.data.duplicate_records);
        }

        if (response && response.data.acknowledged) {
          this.$swal({
            title: globalThis._lang("t_processSuccess"),
            text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } catch (error) {
        console.log("ERROR", error);
      }

      this.$swal({
        title: globalThis._lang("t_processSuccess"),
        text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      this.is_progress = false;
    },
    async uploadBasic() {
      /* if (this.update_match_records && this.selected_cols.length == 0) {
        this.snackbarMessage = "Lütfen güncellenecek olan başlıkları seçiniz.";
        this.snackbarColor = 'warning';
        this.snackbar = true;
        return;
      } */
      this.is_progress = true;

      try {
        let formData = new FormData();
        formData.append("files", this.files, this.files.name);
        formData.append("selected_queue", this.selected_queue);
        formData.append("selectedColumn", this.selectedColumn);
        formData.append("selected_cols", this.selected_cols);
        formData.append("collection", `${this.selected_project}_customers`);
        formData.append("set_passive", this.set_passive);
        formData.append("set_attempts", this.set_attempts);

        formData.append(
          "set_passive_unmatch_records",
          this.set_passive_unmatch_records
        );
        formData.append("update_match_records", this.update_match_records);

        let response = await this.$http_in.post(
          "crm/v1/UploadCRMCustomersBasic",
          formData
        );

        // await this.SetCustomers();
        console.log("dataa", response);
        // if (response.data.data == undefined) {
        //   this.snackbarMessage = response.data;
        //   this.snackbarColor = 'warning';
        //   this.snackbar = true;
        //   this.is_progress = false;
        //   return;
        // }

        if (response.data.duplicate_records.length > 0) {
          // globalThis.window.location.href = `http://${globalThis.AppConfig.RESTAPI}/outputs/${response.data.url}.xlsx`;
          this.onexport(response.data.duplicate_records);
        }

        if (response && response.data.acknowledged) {
          this.$swal({
            title: globalThis._lang("t_processSuccess"),
            text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } catch (error) {
        console.log("ERROR", error);
      }

      this.$swal({
        title: globalThis._lang("t_processSuccess"),
        text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      this.is_progress = false;
    },
  },
  mounted: function () {
    this.get_projects();
    this.get_queues();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

